<template>
  <div class="job-board-list-card cursor-pointer pb-0">
    <div class="title job-card-title d-flex justify-content-between">
      <p class="job-card-title" style="line-height: 1">
        <!-- {{ jobTitle }} &nbsp;|&nbsp; {{ jobId }} -->
        {{ jobTitle }} &nbsp;|&nbsp; {{ jobDisplayId }}&nbsp;
        <span v-if="publishedTime" class="new-flag">NEW</span>
        <span v-if="isOwnJob == false">
          &nbsp;|&nbsp;
          <CBadge color="primary">Shared</CBadge>
        </span>
      </p>
      <div class="d-flex">
        <div v-if="featured" class="featured">
          <span>Featured</span>
        </div>
        <div v-if="item.is_private_job" class="private">
          <span>Private</span>
        </div>
        <div v-if="isAdmins" :class="`status-${jobStatus.class}`">
          <span>{{ jobStatus.content }}</span>
        </div>
      </div>
    </div>
    <div class="location pb-1">
      <div class="organisation mb-2 block1">
        <img
          v-if="orgLogo"
          :src="orgLogo"
          class="rounded-circle ratio-1 mr-1"
          style="width: 38px !important"
          @error="
            $event.target.src = '/img/hospital.png';
            $event.target.style = 'width:38px !important';
          "
          alt="logo"
        />
        <img
          v-else
          src="/img/hospital.png"
          class="rounded-circle mr-1"
          style="width: 38px !important"
          alt="logo"
        />&nbsp; <span> {{ organisation }}</span
        >&nbsp;
      </div>
      <div class="block2 pl-3">
        <span class="text-primary fs-25">
          <i class="fas fa-map-marker-alt text-gray"></i>
        </span>
        <span class="pl-2">
          <span v-if="displayLocation">{{ displayLocation }}</span>
          <span v-else>{{ location }}</span>
        </span>
      </div>
    </div>
    <p class="content text-truncate">{{ jobDescription }}</p>
    <!-- <div v-if="isAdmins">
      <CRow class="text-center count-row">
        <CCol class="count-col">
          <p class="match-span">{{ counts.applied }}</p>
          <p class="m-0">Applied</p>
        </CCol>
        <CCol class="count-col">
          <p class="match-span">{{ counts.review }}</p>
          <p class="m-0">Review</p>
        </CCol>
        <CCol class="count-col">
          <p class="match-span">{{ counts.inProcess }}</p>
          <p class="m-0">In Process</p>
        </CCol>
        <CCol class="p-0">
          <p class="match-span" style="color: red">{{ counts.alerts }}</p>
          <p class="m-0">Alerts</p>
        </CCol>
      </CRow>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { appendBaseUrlWithLink } from "@/helpers/helper";

export default {
  props: ["item", "index"],
  data() {
    return {
      appendBaseUrlWithLink
    }
  },
  computed: {
    ...mapGetters(["getRoleScopes"]),
    featured() {
      return this.item.featured || "";
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
    published() {
      return this.item.published || "";
    },
    jobStatus() {
      switch (this.item?.job_status?.status_id) {
        case 27:
          return { class: "Draft", content: this.item?.job_status?.status };
        case 28:
          return { class: "Pending", content: this.item?.job_status?.status };
        case 29:
          return { class: "Published", content: this.item?.job_status?.status };
        case 30:
          return { class: "Inactive", content: this.item?.job_status?.status };
        case 31:
          return { class: "Archive", content: this.item?.job_status?.status };

        default:
          return { class: "Draft", content: this.item?.job_status?.status };
      }
    },
    isParentReBranding() {
      return this.item?.parent_org_info_required;
    },
    isCandidateOrPublicBoard() {
      return (
        !this.getRoleScopes.length ||
        ["system_candidate", "customer_candidate"].includes(
          this.getRoleScopes[0]
        )
      );
    },
    parent_organisation() {
      return this.item?.organisation?.parent_organisation?.[0];
    },
    orgLogo() {
      if (
        this.isCandidateOrPublicBoard &&
        this.isParentReBranding &&
        this.item?.parent_org_info?.length
      ) {
        return appendBaseUrlWithLink(this.item?.parent_org_info[0]?.logo_url);
      }
      return appendBaseUrlWithLink(this.item?.organisation?.logo_url);
    },
    jobTitle() {
      return this.item?.job_title || "--";
    },
    location() {
      return this.item?.location?.location || "--";
    },
    organisation() {
      if (!this.isOwnJob) {
        return this.item?.organisation_name || "";
      }
      if (this.isCandidateOrPublicBoard && this.isParentReBranding && this.item?.parent_org_info?.length) {
        return this.item?.parent_org_info[0]?.name || "";
      }
      return this.item?.organisation?.name || "";
    },
    postedTime() {
      let created = moment(this.item?.published_on);
      let today = moment();
      let days_diff = today.diff(created, "days");
      return days_diff
        ? `${days_diff} day${days_diff > 1 ? "s" : ""} ago`
        : "Today";
    },
    jobDescription() {
      let result = null;
      if (this.item?.advert_description) {
        var html = this.item?.advert_description;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        result = text.split(" ").slice(0, 11).join(" ");
      }
      return result;
    },
    jobId() {
      return this.item?.job_id;
    },
    jobDisplayId() {
      return this.item?.display_uid;
    },
    // counts() {
    //   return {
    //     applied: this.item?.counts?.applied_count || 0,
    //     review: this.item?.counts?.review_count || 0,
    //     inProcess: this.item?.counts?.in_process_count || 0,
    //     alerts: 0,
    //   };
    // },
    isOwnJob() {
      if (this.item?.is_own_job === null) {
        return true;
      }
      return this.item?.is_own_job;
    },
    publishedTime() {
      let created = moment(this.item?.published_on);
      let today = moment();
      return today.diff(created, "days") <= 7;
    },
    isTFWPublicJobBoard() {
      const { name } = this.$route;
      return ["TFW Job Board", "TFW Job Board Detail"].includes(name) || false;
    },
    displayLocation(){
      return this.item?.display_location;
    }
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.job-card-title {
  margin-bottom: 4px;
}
.posted-time {
  color: $orange;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
}
.status-Pending {
  background-color: orange;
  border: 1px solid orange;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Inactive {
  background-color: red;
  border: 1px solid red;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Archive {
  background-color: red;
  border: 1px solid red;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Draft {
  background-color: $body-bg;
  border: 1px solid $body-bg;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}

.status-Archive {
  background-color: rgb(95, 127, 214);
  border: 1px solid rgb(95, 127, 214);
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}

.status-Published {
  background-color: $green;
  border: 1px solid $green;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.featured {
  background-color: #dd3651;
  border: 1px solid #dd3651;
  padding: 3px 10px;
  margin: 0px 3px;
  border-radius: 6px;
  color: $white;
  min-width: 60px;
  text-align: center;
  max-height: 18px;
}
.private {
  background-color: #696969;
  border: 1px solid #2f4f4f;
  padding: 3px 10px;
  margin: 0px 3px;
  border-radius: 6px;
  color: $white;
  min-width: 60px;
  text-align: center;
  max-height: 18px;
}
.count-row {
  font-size: 12px;
  border-top: 1px solid #d8d8d8;
  margin-left: -4px;
  margin-right: -4px;
  .match-span {
    color: green;
    font-weight: 500;
    margin: 0px;
  }
  .count-col {
    border-right: 1px solid #d8d8d8;
    padding: 0px;
  }
}
.block1,
.block2 {
  display: inline;
}
.new-flag {
  background-color: var(--primary);
  border-radius: 15px 0px 15px 0px;
  padding: 2px 9px;
  font-weight: bold;
  font-size: 9px;
  color: white;
}
</style>
